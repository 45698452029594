import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  methods: {
    promptInfo(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "CheckIcon",
          variant: "success"
        }
      });
    },

    promptError(error) {
      let message;
      if (error instanceof Error) message = this._getErrorMessage(error);
      else message = message = error;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "XIcon",
          variant: "danger"
        }
      });
    },

    _getErrorMessage(e) {
      try {
        let message = e.message;

        if (e.response.data.error.detail) {
          return e.response.data.error.detail;
        }

        if (e.detail) {
          return e.detail;
        }

        return message;
      } catch (e) {
        return e.message;
      }
    }
  },
}